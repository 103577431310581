export const siteURL = process.env.REACT_APP_MAIN_SITE_URL
export const baseURL = process.env.REACT_APP_MAIN_BASE_URL
export const reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY
export const myPageImageUrl = process.env.REACT_APP_MY_PAGE_IMAGE_URL
export const staticUrl = process.env.REACT_APP_MAIN_STATIC_URL
export const supportArticleUrl = process.env.REACT_APP_SUPPORT_ARTICLE_URL
export const geoLocationKey = 'prj_live_sk_6d98dc8a1d5176a349d7226de83908980e1d9a12'
export const vdomainTXT = 'include:servers.emailsandsurveys.com'
export const vdomainCNAME = 'dkim.emailsandsurveys.com'
export const authURL =baseURL+'/auth'
export const profileURL =baseURL+'/member'
export const subUser =baseURL+'/subUser'
export const groupUrl = baseURL + '/group'
export const contactUrl = baseURL + '/contact'
export const contactImportUrl = baseURL + '/contactImport'
export const groupSegmentUrl = baseURL + '/groupSegment'
export const domainUrl = baseURL + '/domain'
export const domainEmailUrl = baseURL + '/domainEmail'
export const contactUsUrl = baseURL + '/contactUs'
export const billingUrl = baseURL + '/billing'
export const quickbookUrl = baseURL + '/quickbook'
export const salesforceUrl = baseURL + '/salesforce'
export const contactHistoryUrl = baseURL + '/contactHistory'
export const smsTemplateUrl = baseURL + '/smsTemplate'
export const callingUrl = baseURL + '/calling'
export const emailCampaignUrl = baseURL + '/emailCampaign'
export const mypagesUrl = baseURL + '/mypages'
export const googleDriveUrl = baseURL + '/googleDrive'
export const smsCampaignUrl = baseURL + '/smsCampaign'
export const surveyUrl = baseURL + '/survey'
export const surveyCategoryUrl = baseURL + '/surveyCategory'
export const surveyTemplateUrl = baseURL + '/surveyTemplate'
export const assessmentGroupsUrl = baseURL + '/assessmentGroups'
export const assessmentUrl = baseURL + '/assessment'
export const assessmentCategoryUrl = baseURL + '/assessmentCategory'
export const assessmentTemplateUrl = baseURL + '/assessmentTemplate'
export const customFormUrl = baseURL + '/customForm'
export const smsPollingUrl = baseURL + '/smsPolling'
export const easDriveUrl = baseURL + '/easDrive'
export const builditformeUrl = baseURL + '/builditforme'
export const dropboxUrl = baseURL + '/dropbox'
export const automationUrl = baseURL + '/automation'
export const onedriveUrl = baseURL + '/onedrive'
export const facebookUrl = baseURL + '/facebook'
export const twitterUrl = baseURL + '/twitter'
export const linkedinUrl = baseURL + '/linkedin'
export const socialMediaCampaignUrl = baseURL + '/socialMediaCampaign'
export const dashboardUrl = baseURL + '/dashboard'
export const calendarUrl = baseURL + '/calendar'
export const calendarAppointmentEventTypeUrl = baseURL + '/calendarAppointmentEventType'
export const calendarAppointmentUrl = baseURL + '/calendarAppointment'
export const googleCalendarUrl = baseURL + '/googleCalendar'
export const outlookCalendarUrl = baseURL + '/outlookCalendar'
export const smsInboxUrl = baseURL + '/smsInbox'
export const emailCampaignReportUrl = baseURL + '/emailCampaignReport'
export const emailCampaignAbTestingReportUrl = baseURL + '/emailCampaignAbTestingReport'
export const smsPollingReportUrl = baseURL + '/smsPollingReport'
export const smsCampaignReportUrl = baseURL + '/smsCampaignReport'
export const socialMediaCampaignReportUrl = baseURL + '/socialMediaCampaignReport'
export const surveyReportUrl = baseURL + '/surveyReport'
export const assessmentReportUrl = baseURL + '/assessmentReport'
export const shopifyUrl = baseURL + '/shopify'
export const zoomUrl = baseURL + '/zoom'
export const planUrl = baseURL + '/plan'
export const brandKitUrl = baseURL + '/brandKit'
export const pollingUrl = baseURL + '/polling'
export const numberForwardingUrl = baseURL + '/numberForwarding'
export const usercontentUrl = siteURL + '/usercontent'

export const parentCompanyTitle = 'Kaiasoft INC'
export const websiteTitle = 'EmailsAndSurveys'
export const websiteTitleWithExt = 'EmailsAndSurveys.com'
export const websiteSmallTitleWithExt = 'emailsandsurveys.com'
export const websiteName = 'www.emailsandsurveys.com'
export const websiteEmailAddress = 'support@emailsandsurveys.com'
export const tokenName = 'emailsandsurveysToken'
export const tenDLCFormUrl = 'https://qa2webapp.emailsandsurveys.com/customform?v=JwUE'
// export const tenDLCFormUrl = 'https://webapp.emailsandsurveys.com/customform?v=JKhC'
export const websiteColor = '#4285f4'
export const smLinkedinUrl = 'https://www.linkedin.com/company/emailsandsurveys-com?original_referer=https%3A%2F%2Fwww.emailsandsurveys.com%2F'
export const smFacebookUrl = 'https://www.facebook.com/emailsandsurveys'
export const smInstagramUrl = 'https://www.instagram.com/emailsandsurveysdotcom/'
export const smTwitterUrl = 'https://twitter.com/emails_surveys'

export const calenderName = 'EAS Calendar'